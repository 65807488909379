
























































































































































































































































































































import useParticipant from "@/use/participant";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import useAuthPermission from "@/use/authPermissions";
import useMisc from "@/use/misc";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MParticipantListItem: () =>
      import("@/components/molecules/participant/m-participant-list-item.vue"),
    MParticipantOptions: () =>
      import("@/components/molecules/participant/m-participant-options.vue"),
    MParticipantInvoice: () =>
      import("@/components/molecules/participant/m-participant-invoice.vue"),
    MSingleInvoice: () =>
      import("@/components/molecules/participant/m-single-invoice.vue"),
    MSendInvoice: () =>
      import("@/components/molecules/participant/m-send-invoice.vue"),
    MParticipantNfc: () =>
      import("@/components/molecules/participant/m-participant-nfc.vue"),
    MRemoveNfc: () => import("@/components/molecules/m-remove-nfc.vue"),
    MEditRequest: () =>
      import("@/components/molecules/participant/m-edit-request.vue"),
    MSurveyDetails: () =>
      import("@/components/molecules/participant/m-survey-details.vue"),
    MGenerateCertificate: () =>
      import("@/components/molecules/participant/m-generate-certificate.vue"),
    MParticipantAbstracts: () =>
      import("@/components/molecules/participant/m-participant-abstracts.vue"),
  },

  props: {
    status: {
      type: String,
      required: true,
      default: "",
    },
    search: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    currency: {
      type: String,
      required: true,
    },
  },

  setup(props, { root }) {
    const {
      getParticipantStatusName,
      getParticipantStatusColor,
      getParticipantInvoiceType,
    } = useParticipant({ root });

    const { getLanguageVersion, lettersItems } = useMisc({ root });

    const state = reactive({
      singleSelect: false,
      selected: [],
      expanded: [],
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "image", text: "", sortable: false, width: 1 },
        {
          value: "createdAt",
          text: root.$tc("layout.misc.registrationDate"),
          width: 100,
        },
        { value: "lastName", text: root.$tc("layout.misc.lastName") },
        {
          value: "firstName",
          text: root.$tc("layout.misc.firstName"),
          sortable: true,
        },
        {
          value: "state",
          text: root.$tc("layout.misc.state"),
          sortable: false,
        },
        {
          value: "group",
          text: root.$tc("layout.misc.groups"),
          sortable: false,
        },
        { value: "icons", text: "", sortable: false, align: "right", width: 1 },
        {
          value: "nfcAssign",
          text: "",
          sortable: false,
          align: "right",
          width: 1,
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "right",
          width: 1,
        },
      ],
      items: [],
      table: false,
      loading: false,
      loaded: false,
      error: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["lastName"],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: "",
      searchTimeout: undefined as any,
      alerts: [],
      participantList: [] as any,
      invoiceModal: false,
      singleInvoice: {},
      nfcAssignDialog: false,
      nfcAssignItem: {},
      nfcAssignIndex: -1,
      specializations: [],
      letterSort: "",
      editRequestDialog: false,
      editRequestItem: {},
      surveyDialog: false,
      surveyDetailsItem: {},
      certificateDialog: false,
      certificateDialogItem: {},
      abstractsDialog: false,
      abstractsDialogItem: {},
      deletInvoiceDialog: false,
    });

    const eventId = computed(() => root.$route.params.id);

    const nfcAssignDialog = (item: any) => {
      state.nfcAssignItem = Object.assign({}, item);
      state.nfcAssignDialog = true;
    };

    const editRequestDialog = (item: any) => {
      state.editRequestItem = Object.assign({}, item);
      state.editRequestDialog = true;
    };

    const showSurveyDetails = (item: any) => {
      state.surveyDetailsItem = Object.assign({}, item);
      state.surveyDialog = true;
    };

    const generateCertificate = (item: any) => {
      state.certificateDialogItem = Object.assign({}, item);
      state.certificateDialog = true;
    };

    const abstractsDetails = (item: any) => {
      state.abstractsDialogItem = Object.assign({}, item);
      state.abstractsDialog = true;
    };

    const fetchSpecialization = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get("specialization")
        .then(({ data: { specializations } }) => {
          state.specializations = specializations;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.specializations = [];
          } else {
            console.log(error);
          }
        });
    };

    onMounted(fetchSpecialization);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${eventId.value}/participant/list`, {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            state: props.status,
            type: props.type || undefined,
            search: state.search || undefined,
            lastName: state.letterSort || undefined,
          },
        })
        .then(({ data: { participants, total } }) => {
          state.items = participants;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
          state.selected = [];
        });
    };

    onMounted(() => (state.loaded = true));

    watch(
      () => props.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    watch(() => state.options, fetchData, { deep: true });
    watch(() => props.status, fetchData, { deep: true });
    watch(() => props.type, fetchData, { deep: true });
    watch(() => state.letterSort, fetchData, { deep: true });

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/participant/`
    );

    const changeStatus = (id: string, status: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        state: status,
      };

      axiosInstance
        .put(`participant/${id}/status`, data, {
          headers: {
            event: eventId.value,
          },
        })
        .then(() => {
          state.error = false;
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
        });
    };

    const changePresence = (id: string, presence: boolean) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        wasPresent: presence,
      };

      axiosInstance
        .put(`participant/${id}/presence`, data, {
          headers: {
            event: eventId.value,
          },
        })
        .then(() => {
          state.error = false;
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
        });
    };

    const getInvoice = (id: number) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`/invoice/${id}`, { params: { type: 1 } })
        .then(({ data }) => {
          state.singleInvoice = data;
        })
        .catch((error) => {
          console.log(error);
          state.singleInvoice = {};
        });
    };

    const deleteInvoice = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .delete(`/invoice/participant/${id}`, {
          headers: {
            event: eventId.value,
          },
        })
        .then(() => {
          state.deletInvoiceDialog = false;
          fetchData();
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.invoiceDeleted"),
          });
        })
        .catch((error) => console.log(error));
    };

    const { participantsManagement, participantsInvoice } = useAuthPermission({
      root,
    });

    const checkIfCertificateExists = (groups: Array<any>) => {
      const hasCertificate = groups.filter(
        (el) => el.certificateTemplates && el.certificateTemplates.length
      );
      if (hasCertificate.length > 0) return true;
    };

    return {
      state,
      eventId,
      fetchData,
      filesUrl,
      getParticipantStatusName,
      getParticipantStatusColor,
      getParticipantInvoiceType,
      getLanguageVersion,
      lettersItems,
      changeStatus,
      changePresence,
      getInvoice,
      participantsManagement,
      participantsInvoice,
      nfcAssignDialog,
      editRequestDialog,
      showSurveyDetails,
      checkIfCertificateExists,
      generateCertificate,
      abstractsDetails,
      deleteInvoice,
    };
  },
});
